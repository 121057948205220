import "./App.css";
import moment from "moment";
import "moment-timezone";
import React, { useState, useEffect } from "react";
import Disclaimer from "./js/disclaimer";
import FAQ from "./js/faq";
import ContactUs from "./js/contact";
import PrivacyPolicy from "./js/privacy";
import AboutUs from "./js/about";
import trackVisitor from "./utilities/tracker";
import DaySattaResult from "./js/daySattaResult";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";

function App() {
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  const location = useLocation();
  const isDisc = location.pathname.includes("/disclaimer");
  const isContact = location.pathname.includes("/contact");
  const isPrivacy = location.pathname.includes("/privacypolicy");
  const isAbout = location.pathname.includes("/about");
  const isFaq = location.pathname.includes("/faq");

  var currentDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  const [data, setData] = useState([]);
  const [datagame, setDataFor] = useState([]);
  var prevDate = moment()
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");
  useEffect(() => {
    trackVisitor();
  });

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);
  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm");
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]);

  return (
    <div className="App">
      {/* seo setup start */}
      <Helmet>
        <title></title>
        <meta name="description" content="sattasport, satta sport, sattaking" />
        <meta name="Keywords"
          content="sking, s king s-king, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king," />
        <link rel="canonical" href="https://s-king.co/" />
      </Helmet>
      {/* seo setup end */}
      <div className="col-12 homeHeader">
        <div className="row main-row">
          <div className="col-md-4 col-sm-12 text-center text-danger fw-b link">
            <a href="/">HOME</a>
          </div>
          <div className="col-md-4 col-sm-12 text-center text-danger fw-b link">
            <a href="http://sattasport.co/">SATTA KING</a>
          </div>
          <div className="col-md-4 col-sm-12 text-center text-danger fw-b link">
            <a href="http://satta-king-black.co.in/">SATTA BLACK</a>
          </div>
        </div>
      </div>
      <marquee className="text-warning">
        Satta King Result, SattaKing, Satta, Satta.Com, Satta Com, Gali Result,
        Satta News, Today Satta Result, Live Satta King, Satta Aaj Ka Satta
        Result, Gali Result Today{" "}
      </marquee>
      <div className="col-12 text-center header-heading">
        <h6>SATTA KING GAME RECORD CHART</h6>
        <h1>SATTA KING</h1>
      </div>

      <div className="text-center currentBanner mt-3">
        <h6>हा भाई यही आती हे सबसे तेज़ खबर रूको और धेखो</h6>
        {datagame?.map((todayData, index) => (
          <div key={index} className="game">
            <p className="mb-0">{todayData?.gameName}</p>
            <button className=" result-button blinking-text">
              {todayData?.result || ""}
            </button>
          </div>
        ))}
      </div>
      <p className="text-center currentBanner">
        <a href="/">
          <button className="result-button">Result Refresh</button>{" "}
        </a>
      </p>
      <AdvertisementComponent type='even' />
      <DaySattaResult dayGameData={data} />
      {isDisc && <Disclaimer />}
      {isContact && <ContactUs style={{ display: "none" }} />}
      {isPrivacy && <PrivacyPolicy style={{ display: "none" }} />}
      {isAbout && <AboutUs style={{ display: "none" }} />}
      {isFaq && <FAQ style={{ display: "none" }} />}
    </div>
  );
}

export default App;
